<template>
  <content-wrapper>
    <h2>About Us</h2>
    <Grid>
      <picture>
        <source srcset="@/assets/bible.webp" type="image/webp">
        <source srcset="@/assets/bible.jpg" type="image/jpeg">
        <img src="@/assets/bible.jpg" alt="Bible on wooden pallet open to the book of Psalms">
      </picture>
      <div>
        <h3>Don't Despise Small Beginnings.</h3>
        <p>
          Though we have started small, we have already seen exponential growth
          as a company. Our founder, John Amoruso, has grown up in the
          manufacturing process of our various products. Now we're taking our
          expertise nationwide to benefit you. With the unique experience each
          member of our team has we are able to get the job done for you better
          than anyone else. We have connections with facilities in over 41 states
          and growing and we're rapidly acquiring more. Will you be one of
          them?
          <br />
          As you can probably tell from our website, we are a Christian-based
          company. Our founder and many of our employees have a great and
          powerful testimony of how our Heavenly Father, God Almighty, has
          dramatically impacted our lives for all the better. As such, we
          operate our business, to the best of our ability, under Biblical
          principles and desire to honor God with each one of our deals.
        </p>
      </div>
    </Grid>
  </content-wrapper>
</template>

<script>
import ContentWrapper from '@/components/ContentWrapper';
import Grid from '@/components/Grid';

export default {
  name: 'About',
  components: {
    ContentWrapper,
    Grid,
  },
};
</script>

<style lang="scss" scoped>
img {
  object-fit: cover;
  width: 100%;
}

p {
  padding-right: 25px;
  padding-bottom: 25px;
}
</style>
